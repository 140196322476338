<template>
  <div class="col-12 w-100" v-if="getGroupData.length > 0">
   
    <div
      class="w-100"
      v-for="(group, groupIndex) in getGroupData"
      :key="groupIndex"
    >
      <div class="groupTitle row w-100 m-0 p-0 mb-3" v-if="getGroupProducts(group, getGroupBy).length > 0">
        <div class="col-12">
          <p
            class="m-0 p-0"
            :style="groupTitleStyles(pro.style.groupProductsStyle)"
          >
            {{ group }}
          </p>
        </div>
      </div>


      <div
        class="row m-0 p-0 w-100"
       
      >
        <div
          :class="[
            isTest && !isDesktopView
              ? `col-${12 / pro.productsPerPageMobile}`
              : `col-${12 / pro.productsPerPageMobile} col-sm-${
                  12 / pro.productsPerPage
                }`,
                getGroupProducts(group, getGroupBy).length <= 2 ? 'mx-auto' : null,
          ]"
          class="mb-3"
          v-for="(product, index) in getGroupProducts(group, getGroupBy)"
          :key="index"
          v-show="showGroupProductsLimitation(index)"
        >
          <div
            class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
            :style="
              getCardBackground(pro.style) +
              getCardBorderCSS(pro.style) +
              getPagePadding(pro.style) +
              getFontFamily(pro.style) +
              ListProductMaxWidth(
                isDesktopView ? pro.productsPerPage : pro.productsPerPageMobile
              )
            "
          >
            <div
              style="
                position: absolute;
                top: -2%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 100;
              "
              class="d-flex justify-content-center"
              v-if="pro.showProductRank && index <= products.length"
            >
              <p
                style="text-align: center"
                :style="getProductRankCss(pro.style)"
                v-if="pro.productRankOption[index] && pro.productRankOption[index].text != ''"
              >
                <span>{{ pro.productRankOption[index].text }}</span>
              </p>
            </div>

            <div>
              <div
                v-if="
                showImage(getProductInfoDetail(
                                      pro.useProductVariant,
                                      product,
                                      'image'
                                    ))
                 
                "
                style="height: 100%"
              >
                <div
                  v-if="
                    checkFileType(
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        'image'
                      )
                    ) == 'video'
                  "
                  :style="ListImageAspectRatio(pro.style)"
                >
                  <video
                    ref="videoRef"
                    :src="`${getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      'image'
                    )}`"
                    :style="
                      getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                    "
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      height: 100%;
                      width: 100%;
                    "
                    controls
                  ></video>
                </div>
                <div v-else :style="ListImageAspectRatio(pro.style)">
                  <LazyImage
                    v-if="
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        'image'
                      ).startsWith('upload')
                    "
                    :src="
                      '/' +
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        'image'
                      )
                    "
                    alt=""
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      height: 100%;
                      width: 100%;
                    "
                    :style="
                      getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                    "
                  />
                  <LazyImage
                    v-else
                    :src="
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        'image'
                      )
                    "
                    alt=""
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      height: 100%;
                      width: 100%;
                    "
                    :style="
                      getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                    "
                  />
                </div>
              </div>
             
            </div>
            <div class="mt-2 flex-grow-1" :style="getPaddingCss(pro.style)">
              <div class="d-flex flex-column h-100">
                <!-- Product Title -->
                <div class="px-3">
                  <p :style="getTitleStyles(pro.style)">
                    {{
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        "title"
                      )
                    }}
                  </p>
                  <!-- Rating Div start -->
                  <div
                    v-if="product.rating && isJudgeMeInstalled"
                    class="Review"
                    v-html="product.rating"
                    :style="getPriceColor(pro.style)"
                  ></div>
                  <!-- Rating Div end -->

                 
                </div>
                <!-- Product Price , features & buy button -->
                <div
                  class="d-flex flex-column justify-content-between flex-grow-1"
                >
                  <div class="">
                    <div v-if="!pro.hideDescription">
                      <div
                        class="d-flex align-items-start mt-1"
                        :style="
                          limitProductDescription(
                            pro.limitDescription,
                            product.readMore
                          )
                        "
                      >
                        <div
                          v-if="
                            pro.useBulletDescription &&
                            product.bullet_description &&
                            product.bullet_description.length
                          "
                        >
                          <div
                            v-for="(
                              bulletPoint, index2
                            ) in product.bullet_description"
                            :key="index2"
                          >
                            <div
                              class="d-flex align-items-start mt-1"
                              :style="getBulletStyles(pro.style)"
                            >
                              <div class="mr-3">
                                <i
                                  class="fa"
                                  :class="pro.selectedBulletIcon"
                                ></i>
                              </div>
                              <span style="text-align: start">{{
                                bulletPoint
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="w-100"
                          v-else
                          :style="`font-family:${pro.style.fontFamily};`"
                          v-html="product.description"
                        ></div>
                      </div>
                      <div
                        class=""
                        v-if="
                          pro.limitDescription &&
                          product.description &&
                          product.description.split(' ').join('').length > 150 && false
                        "
                      >
                        <button
                          class="btn"
                          :class="product.readMore ? 'mt-2' : 'mt-2'"
                          :style="getPriceColor(pro.style)"
                          @click="product.readMore = !product.readMore"
                        >
                        <span>{{ product.readMore ?  translateText("Less info") : translateText("More info") }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div
                    class="d-flex align-items-center"
                    :style="'justify-content:' + pro.style.textAlign + ';'"
                  >
                    <p
                      v-if="product.price && !isHidePrice"
                      :style="getPriceColor(pro.style)"
                      class="m-0 p-0 mr-2"
                    >
                      <span
                        v-if="CurrencySign && currencyPos == 'left'"
                        :class="currencySpace ? 'mr-1' : null"
                        >{{ CurrencySign }}</span
                      >{{
                        getProductInfoDetail(
                          pro.useProductVariant,
                          product,
                          "price"
                        )
                      }}<span
                        v-if="CurrencySign && currencyPos == 'right'"
                        :class="currencySpace ? 'ml-1' : null"
                        >{{ CurrencySign }}</span
                      >
                    </p>
                    <p
                    class="m-0 p-0"
                      v-if="
                        product.compare_at_price &&
                        product.price !== product.compare_at_price &&
                        !isHidePrice
                      "
                      :style="getPriceColor(pro.style)"
                    >
                      <s>
                        <span
                          v-if="CurrencySign && currencyPos == 'left'"
                          :class="currencySpace ? 'mr-1' : null"
                          >{{ CurrencySign }}</span
                        >{{ product.compare_at_price
                        }}<span
                          v-if="CurrencySign && currencyPos == 'right'"
                          :class="currencySpace ? 'ml-1' : null"
                          >{{ CurrencySign }}</span
                        >
                      </s>
                    </p>
                  </div>

                  <div class="mt-3" v-if="product.detail_link">
                    <div
                      class="pb-2"
                      v-if="
                        pro.useProductVariant && product && product.variants
                      "
                    >
                      <VaraintSelectComp :pro="pro" :product="product" />
                    </div>

                    <button
                    class="ButtonHover"
                      v-if="pro.useAddToCart"
                      :style="getProductButtonCss(pro.style)"
                      :disabled="disableAddtoCartBtn"
                      @click="AddToCart(product, index)"
                    >
                      <span v-if="!product.loading">{{
                        pro.useAddToCartText || "Add to Cart"
                      }}</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                    <button
                    class="ButtonHover"
                      v-else
                      :style="getProductButtonCss(pro.style)"
                      @click="goToURLClicked(product.detail_link,product.id)"
                    >
                      {{ pro.buttonText }}
                    </button>
                  </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>

  <div v-else class="col-12 w-100">
    <div class="col-12">
      <h5 class="" style="text-align: center">No groups selected!</h5>
      <p class="" style="text-align: center">
        Please select groups for this component to work
      </p>
    </div>
  </div>
</template>

<script>
import cssFunctionMixin from "../../../customize/mixin/cssFunction";
import { mapGetters } from "vuex";
export default {
  props: [
    "pro",
    "isTest",
    "isDesktopView",
    "products",
    "displayProductsLength",
    "isJudgeMeInstalled",
    "isHidePrice",
    "CurrencySign",
    "currencyPos",
    "currencySpace",
    "disableAddtoCartBtn",
    "AddToCart",
    "goToURLClicked",
    "getProductInfoDetail",
    "checkFileType",
    "getImageFit",
    "getImageTopBorderCSs",
    "getTitleStyles",
    "getPriceColor",
  ],
  components: {
    VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../../VaraintSelectComp.vue"
      ),
  },
  mixins: [cssFunctionMixin],
  computed: {
    ...mapGetters(["translateText"]),
    getGroupData() {
      return this.pro.groupProducts.groupData;
    },
    getGroupBy() {
      return this.pro.groupProducts.groupBy;
    },
    isLimitProduct() {
      return this.pro.groupProducts.limitProductsPerGroup;
    },
    LimitProductValue() {
      return this.pro.groupProducts.productsPerGroup;
    },
  },
  methods: {
    showImage(image) {
      if(image){
        return !(
    image.includes("default_start_page.png") || 
    image.includes("/images/image_placeholder.png")
  );
      }
      return false

},
    groupTitleStyles(property) {
      return `color:${property?.color || "#000"};
        font-size:${property?.fontSize}px;
         font-weight:${property?.fontWeight || "bold"}
        
        ; font-style:${property?.fontStyle || "normal"}
        
        ;text-decoration:${property?.textDecoration || "unset"}
        ;
        font-family:${property?.fontFamily || "Poppins"}
        
        ; text-align:${property?.textAlign || "center"}
        
        ;`;
    },
    showGroupProductsLimitation(index) {
      if (this.isLimitProduct) {
        if (index < this.LimitProductValue) return true;
        return false;
      }

      return true;
    },
    getGroupProducts(group, groupBy) {
      let groupProducts = [...this.products];
      if (this.isTest) {
        return this.products;
      } else {
        return groupProducts.filter((product) => {
          if (groupBy === "Tags") {
            // If product has tags and it is not null or an empty array
            if (
              product.tags &&
              Array.isArray(product.tags) &&
              product.tags.length > 0
            ) {
              // Either don't filter the product or check if the group is included in the tags
              return  product.tags.includes(group);
            }
            // For cases where tags is null or an empty array
            return false;
          } else if (
            groupBy === "Collections" &&
            product.collections &&
            Array.isArray(product.collections)
          ) {
            return product.collections.includes(group);
          }
          return [];
        });
      }
    },

    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property) {
      return (
        "; text-align:" +
        property.textAlign +
        "; padding:" +
        property.pagePaddingTop +
        "px " +
        property.pagePaddingRight +
        "px " +
        property.pagePaddingBottom +
        "px " +
        property.pagePaddingLeft +
        "px; "
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
  },
};
</script>

<style scoped></style>
